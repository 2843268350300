<template>
  <div class="coupon-form">
    <div v-if="coupon" class="form-inner">
      <div v-if="claimed" class="claimed">
        <p>
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        </p>
        <h3>Gutschein verschickt</h3>
        <p>Der Gutschein wurde an die hinterlegte E-Mail Adresse versandt. Vielen Dank!</p>
      </div>
      <div v-else>
        <h2>{{ coupon.name }}</h2>
        <p>{{ coupon.description }}</p>

        <div class="input-wrap">
          <div class="row row-gutter-20">
            <div class="col-12">
              <div class="form-wrap">
                <label for="first_name">Vorname</label>
                <input v-model="user.first_name" type="text" class="form-input">
              </div>
            </div>
            <div class="col-12">
              <div class="form-wrap">
                <label for="last_name">Nachname</label>
                <input v-model="user.last_name" type="text" class="form-input">
              </div>
            </div>
          </div>

          <div class="form-wrap">
            <label for="email">E-Mail Adresse</label>
            <input v-model="user.email" type="text" class="form-input">
          </div>
        </div>

        <p><a @click="claimCoupon" class="button button-yellow button-round button-100">Jetzt Coupon anfordern ›</a></p>

      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "coupon_form",
  data() {
    return {
      loading: true,
      coupon: null,
      claimed: false,
      user: {
        first_name: "",
        last_name: "",
        email: ""
      }
    };
  },
  methods: {
    getQrRedirect() {
      axios
        .get(process.env.VUE_APP_BASE_API + "/qr_redirects/" + this.$route.params.id)
        .then((response) => {
          this.coupon = response.data.coupon;
        });
    },
    claimCoupon() {
      axios
        .post(process.env.VUE_APP_BASE_API + "/qr_redirects/" + this.$route.params.id + "/claim", this.user)
        .then(() => {
          this.claimed = true;
        })
        .catch((error) => {
          error.response.data.errors.forEach((value) => {
            this.$notify({
              group: "notification",
              type: "error",
              text: value,
            });
          });
        });
    }
  },
  mounted() {
    this.getQrRedirect();
  },
};
</script>

<style lang="scss">

  .coupon-form {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #f9f9f9;
    z-index: 99999;

    .form-inner {
      width: 100%;
      max-width: 800px;
      background: #fff;
      margin: 20px auto;
      border: 1px solid #f1f1f1;
      padding: 30px;
      border-radius: 5px;

      h2 {
        margin: 0;
        font-size: 22px;
      }

      p {
        font-size: 16px;
        margin: 5px 0 0 0;
      }

      .input-wrap {
        margin-top: 15px;
      }

      .form-wrap {
        margin-bottom: 15px;
      }

      .button-100 {
        width: 100%;
        text-align: center;
      }
    }
  }

  .claimed {
    text-align: center;
    padding: 40px 0;

    svg {
      width: 80px;
      color: green;
    }

    h3 {
      font-size: 30px;
      margin: 10px 0 0 0;
    }
  }

</style>
